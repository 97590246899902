/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

// 求职招聘
const router = {
    path: '/list',
    component: Layout,
    children: [{
        path: '/list',
        name: 'list',
        meta: {
            title: "智慧就业"
        },
        component: () =>
            import ('@/views/recruitment/list.vue')
    }, {
        path: '/list/positionlist/:Id',
        name: 'positionlist',
        meta: {
            title: "职位列表"
        },
        component: () =>
            import ('@/views/recruitment/positionlist.vue')
    }, {
        path: '/list/recruitment/',
        name: 'recruitment',
        meta: {
            title: "求职招聘"
        },
        component: () =>
            import ('@/views/recruitment/login.vue')
    }, {
        path: '/list/firmlogin/',
        name: 'firmlogin',
        meta: {
            title: "企业端登录"
        },
        component: () =>
            import ('@/views/recruitment/firmlogin.vue')
    }, {
        path: '/list/firmlogin/jobList',
        name: 'jobList',
        meta: {
            title: "职位管理"
        },
        component: () =>
            import ('@/views/recruitment/jobList.vue')
    }, {
        path: '/list/firmlogin/jobList/applyfrom',
        name: 'applyfrom',
        meta: {
            title: "职位申请"
        },
        component: () =>
            import ('@/views/recruitment/applyfrom.vue')
    }, {
        path: '/list/firmlogin/jobList/position',
        name: 'position',
        meta: {
            title: "发布职位"
        },
        component: () =>
            import ('@/views/recruitment/position.vue')
    }, {
        path: '/list/jobhunter/resume',
        meta: {
            title: "个人简历"
        },
        component: () =>
            import ('@/views/recruitment/resume.vue')
    }, {
        path: '/list/firmlogin/jobList/jobResume',
        name: 'jobResume',
        meta: {
            title: "求职者简历"
        },
        component: () =>
            import ('@/views/recruitment/jobResume.vue')
    }, {
        path: '/list/detail/:Id',
        name: 'detail',
        meta: {
            title: "职位详情"
        },
        component: () =>
            import ('@/views/recruitment/detail.vue')
    }, {
        path: '/list/resume/Add/:Id',
        meta: {
            title: "简历添加"
        },
        component: () =>
            import ('@/views/recruitment/Add.vue')
    }, {
        path: '/list/jobhunter/send',
        name: 'send',
        meta: {
            title: "投递简历"
        },
        component: () =>
            import ('@/views/recruitment/send.vue')
    }, {
        path: '/list/jobhunter',
        name: 'jobhunter',
        meta: {
            title: "求职者"
        },
        component: () =>
            import ('@/views/recruitment/jobhunter.vue')
    }, {
        path: '/list/company',
        name: 'company',
        meta: {
            title: "企业端"
        },
        component: () =>
            import ('@/views/recruitment/company.vue')
    }, {
        path: '/list/company/apply',
        name: 'apply',
        meta: {
            title: "职位申请"
        },
        component: () =>
            import ('@/views/recruitment/apply.vue')
    }, {
        path: '/list/company/jobname',
        name: 'jobname',
        meta: {
            title: "求职者推荐"
        },
        component: () =>
            import ('@/views/recruitment/jobname.vue')
    }, {
        path: '/list/company/Info',
        name: 'Info',
        meta: {
            title: "企业信息"
        },
        component: () =>
            import ('@/views/recruitment/Info.vue')
    }, {
        path: '/list/company/candidates',
        name: 'candidates',
        meta: {
            title: "候选人名单"
        },
        component: () =>
            import ('@/views/recruitment/candidates.vue')
    }, {
        path: '/list/company/imgs',
        name: 'imgs',
        meta: {
            title: "候选人名单"
        },
        component: () =>
            import ('@/views/recruitment/imgs.vue')
    }, {
        path: '/list/corporate',
        name: 'corporate',
        meta: {
            title: "企业详情"
        },
        component: () =>
            import ('@/views/recruitment/corporate.vue')
    }]
}

export default router