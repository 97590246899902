import Layout from '@/layout'

const villageRouter = {
    path: '/anLuoYun',
    component: Layout,
    children: [{
            path: '/',
            name: 'anLuoYun',
            meta: {
                title: "安洛云首页"
            },
            component: () =>
                import ('@/views/anLuoYun/index.vue')
        },
        {
            path: '/anLuoYun/about',
            name: 'about',
            meta: {
                title: "关于我们"
            },
            component: () =>
                import ('@/views/anLuoYun/about.vue')
        },
        {
            path: '/anLuoYun/serve',
            name: 'serve',
            meta: {
                title: "企业服务"
            },
            component: () =>
                import ('@/views/anLuoYun/serve.vue')
        },
        {
            path: '/anLuoYun/recruit',
            name: 'recruit',
            meta: {
                title: "企业招聘"
            },
            component: () =>
                import ('@/views/anLuoYun/recruit.vue')
        },
        {
            path: '/anLuoYun/message',
            name: 'message',
            meta: {
                title: "企业资讯"
            },
            component: () =>
                import ('@/views/anLuoYun/message.vue')
        },
        {
            path: '/anLuoYun/contact',
            name: 'contact',
            meta: {
                title: "联系我们"
            },
            component: () =>
                import ('@/views/anLuoYun/contact.vue')
        },
    ]
}

export default villageRouter