/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const villageRouter = {
  path: '/village',
  component: Layout,
  children: [{
    path: '',
    meta: {
      title: "我的社区"
    },
    component: () =>
      import('@/views/village/index.vue')
  },
  {
    path: 'community',
    meta: {
      title: "我的社区"
    },
    component: () =>
      import('@/views/village/community.vue')
  },
  {
    path: 'region',
    meta: {
      title: "我的小区"
    },
    component: () =>
      import('@/views/village/region.vue')
  },
  {
    path: 'hygiene',
    meta: {
      title: "卫生医疗"
    },
    component: () =>
      import('@/views/village/hygiene.vue')
  }]
}

export default villageRouter
