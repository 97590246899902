import Layout from '@/layout'

const villageRouter = {
    path: '/zhengxie',
    component: Layout,
    children: [{
        path: '/zhengxie',
        meta: {
            title: "宜阳智慧政协"
        },
        component: () =>
            import ('@/views/zhengxie/index.vue')
    }, {
        path: '/zhengxie/meeting',
        meta: {
            title: "远程协商"
        },
        component: () =>
            import ('@/views/zhengxie/meeting.vue')
    }, {
        path: '/zhengxie/video',
        meta: {
            title: "视频会议"
        },
        component: () =>
            import ('@/views/zhengxie/video.vue')
    }, ]
}

export default villageRouter