import Layout from '@/layout'

const villageRouter = {
    path: '/CalYl',
    component: Layout,
    children: [{
        path: '/CalYl',
        name: '/CalYl',
        meta: {
            title: "首页"
        },
        component: () =>
            import ('@/views/CalYl/home.vue')
    }, {
        path: '/CalYl/login',
        meta: {
            title: "登录"
        },
        component: () =>
            import ('@/views/CalYl/login.vue')
    }, {
        path: '/CalYl/history',
        name: '/CalYl/history',
        meta: {
            title: "历史订单"
        },
        component: () =>
            import ('@/views/CalYl/history.vue')
    }, {
        path: '/CalYl/work',
        meta: {
            title: "工作台"
        },
        component: () =>
            import ('@/views/CalYl/work.vue')
    }, {
        path: '/CalYl/personal/:id',
        meta: {
            title: "订单详情"
        },
        component: () =>
            import ('@/views/CalYl/personal.vue')
    }, {
        path: '/CalYl/prompt',
        name: '/CalYl/prompt',
        meta: {
            title: "提醒信息"
        },
        component: () =>
            import ('@/views/CalYl/prompt.vue')
    }, {
        path: '/CalYl/service',
        name: '/CalYl/service',
        meta: {
            title: "服务管理"
        },
        component: () =>
            import ('@/views/CalYl/service.vue')
    }, {
        path: '/CalYl/service/editor/',
        name: '/CalYl/service/editor/',
        meta: {
            title: "服务编辑"
        },
        component: () =>
            import ('@/views/CalYl/editor.vue')
    }]
}

export default villageRouter