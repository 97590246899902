/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/pension',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'pension',
      meta: {
        title: "智慧养老"
      },
      component: () =>
        import('@/views/pension/index.vue')
    },
    {
      path: 'healthy',
      name: 'healthy',
      meta: {
        title: "健康档案"
      },
      component: () =>
        import('@/views/pension/healthy.vue')
    },
    // {
    //     path: 'shequ',
    //     name: 'shequ',
    //     meta: {
    //         title: "社区养老"
    //     },
    //     component: () =>
    //         import ('@/views/pension/shequ.vue')
    // },
    {
      path: 'dangAn',
      name: 'dangAn',
      meta: {
        title: "健康档案"
      },
      component: () =>
        import('@/views/pension/dangAn.vue')
    },
    {
      path: 'tiJianJiLv',
      name: 'tiJianJiLv',
      meta: {
        title: "体检记录"
      },
      component: () =>
        import('@/views/pension/tiJianJiLv.vue')
    },
    {
      path: 'shangBao',
      name: 'shangBao',
      meta: {
        title: "健康上报"
      },
      component: () =>
        import('@/views/pension/shangBao.vue')
    },
    {
      path: 'gerenXx',
      name: 'gerenXx',
      meta: {
        title: "个人信息"
      },
      component: () =>
        import('@/views/pension/gerenXx.vue')
    },
    {
      path: 'xinXiBianJi',
      name: 'xinXiBianJi',
      meta: {
        title: "编辑个人信息"
      },
      component: () =>
        import('@/views/pension/xinXiBianJi.vue')
    },
    {
      path: '/pension/xxList/:groupId',
      name: 'xxList',
      meta: {
        title: "健康信息列表"
      },
      component: () =>
        import('@/views/pension/xxList.vue')
    },
    {
      path: 'ysZhiShi',
      name: 'ysZhiShi',
      meta: {
        title: "养生知识"
      },
      component: () =>
        import('@/views/pension/ysZhiShi.vue')
    },
    {
      path: '/pension/zsXiangQing/:Id',
      name: 'zsXiangQing',
      meta: {
        title: "养生知识"
      },
      component: () =>
        import('@/views/pension/zsXiangQing.vue')
    },
  ]
}

export default router