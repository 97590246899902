
const state = {
  direction: "forward",
}

const mutations = {
  UPDATE_DIRECTION: (state, direction) => {
    state.direction = direction
  }
}

const actions = {
}

export default {
  state,
  mutations,
  actions
}

