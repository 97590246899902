/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const villageRouter = {
  path: '/wangge',
  component: Layout,
  children: [{
    path: '',
    name: 'wangge',
    meta: {
      title: "小区管理"
    },
    component: () =>
      import('@/views/wangge/xiaoqu.vue')
  }, {
    path: '/wangge/danyuan/:BId/:GId/:RId',
    name: 'danyuan',
    meta: {
      title: "单元"
    },
    component: () =>
      import('@/views/wangge/danyuan.vue')
  },
  {
    path: '/wangge/danyuan/jiating/:BRId/:ShopShow/:RId/:Code/:BFId/:BId/:BUId',
    name: 'jiating',
    meta: {
      title: "家庭列表"
    },
    component: () =>
      import('@/views/wangge/jiating.vue')
  },
  {
    path: 'jiating/:Id',
    name: 'jiatingAdd',
    meta: {
      title: "家庭列表"
    },
    component: () =>
      import('@/views/wangge/jiating.vue')
  },
  {
    path: '/wangge/danyuan/jiating/jtbianji/:Id',
    name: 'jtbianji',
    meta: {
      title: "家庭成员编辑"
    },
    component: () =>
      import('@/views/wangge/jtbianji.vue')
  },
  {
    path: 'xqbianji/:Id',
    name: 'xqbianji',
    meta: {
      title: "小区编辑"
    },
    component: () =>
      import('@/views/wangge/xqbianji.vue')
  },
  {
    path: 'chengyuanXq/:Id',
    name: 'chengyuanXq',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/wangge/chengyuanXq.vue')
  },
  {
    path: '/wangge/danyuan/jiating/addroom/:Id',
    name: 'roomAdd',
    meta: {
      title: "保存房屋"
    },
    component: () =>
      import('@/views/wangge/roomAdd.vue')
  }, {
    path: '/wangge/fangkong/fkYiMiao',
    name: 'danyuan',
    meta: {
      title: "疫苗接种"
    },
    component: () =>
      import('@/views/wangge/fangkong/fkYiMiao.vue')
  }, {
    path: '/wangge/fangkong/fkYiMiao/ymXiangQing/:Id',
    name: 'ymXiangQing',
    meta: {
      title: "疫苗接种"
    },
    component: () =>
      import('@/views/wangge/fangkong/ymXiangQing.vue')
  }, {
    path: '/wangge/fangkong/fkFanQi',
    name: 'fkFanQi',
    meta: {
      title: "返(来)洛人员"
    },
    component: () =>
      import('@/views/wangge/fangkong/fkFanQi.vue')
  }, {
    path: '/wangge/fangkong/fkFanQi/fqXiangQing/:Id',
    name: 'fqXiangQing',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/wangge/fangkong/fqXiangQing.vue')
  }, {
    path: '/wangge/fangkong/fkchuqi',
    name: 'fkchuqi',
    meta: {
      title: "在外人员"
    },
    component: () =>
      import('@/views/wangge/fangkong/fkchuqi.vue')
  }, {
    path: '/wangge/fangkong/fkchuqi/cqXiangQing/:Id',
    name: 'cqXiangQing',
    meta: {
      title: "在外人员"
    },
    component: () =>
      import('@/views/wangge/fangkong/cqXiangQing.vue')
  }, {
    path: '/wangge/fangkong/fkGuanKong',
    name: 'fkGuanKong',
    meta: {
      title: "管控人员"
    },
    component: () =>
      import('@/views/wangge/fangkong/fkGuanKong.vue')
  }, {
    path: '/wangge/fangkong/fkGuanKong/gkXiangQing/:Id',
    name: 'gkXiangQing',
    meta: {
      title: "管控人员"
    },
    component: () =>
      import('@/views/wangge/fangkong/gkXiangQing.vue')
  }, {
    path: '/wangge/fangkong/fkFanQi/fqXiangQing/fanqiQueRen/:Id',
    name: 'fanqiQueRen',
    meta: {
      title: "确认情况"
    },
    component: () =>
      import('@/views/wangge/fangkong/fanqiQueRen.vue')
  }
  ]
}

export default villageRouter