
const state = {
  token: '',
  name: '',
  openId: '',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_OPENID: (state, openId) => {
    state.openId = openId
  },
}

const actions = {
  setopenid({ commit }, { openid }) {
    commit('SET_OPENID', openid)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
