/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

// 求职招聘
const router = {
    path: '/oldman',
    component: Layout,
    children: [{
        path: '/oldman',
        name: 'oldman',
        meta: {
            title: "居民养老"
        },
        component: () =>
            import ('@/views/oldman/shouye.vue')
    }, {
        path: '/dengji/lrdjShouYe/',
        name: 'lrdjShouYe',
        meta: {
            title: "居民养老"
        },
        component: () =>
            import ('@/views/oldman/dengji/lrdjShouYe.vue')
    }, {
        path: '/dengji/oldmanAdd/:id',
        name: 'oldmanAdd',
        meta: {
            title: "信息登记"
        },
        component: () =>
            import ('@/views/oldman/dengji/oldmanAdd.vue')
    }, {
        path: '/dengji/lrdjChaXun/',
        name: 'lrdjChaXun',
        meta: {
            title: "信息查询"
        },
        component: () =>
            import ('@/views/oldman/dengji/lrdjChaXun.vue')
    }, {
        path: '/shenbao/shenbaoAdd/:OrganId',
        name: 'shenbaoAdd',
        meta: {
            title: "自主申报"
        },
        component: () =>
            import ('@/views/oldman/shenbao/shenbaoAdd.vue')
    }, {
        path: '/shenbao/shenbaoShouYe/',
        name: 'shenbaoShouYe',
        meta: {
            title: "自主申报"
        },
        component: () =>
            import ('@/views/oldman/shenbao/shenbaoShouYe.vue')
    }]
}

export default router